import type { CustomElement } from '@integrabeauty/custom-elements';
import { startTimer, timeLeft } from '../../../lib/countdown-timer.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders product related countdown timer. It is used in a product template context.
 */
class ProductDetailCountdownTimer extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-heading',
      'data-end-time',
      'data-start-time'
    ];
  }

  readonly dataset!: {
    /**
     * Stop time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    endTime: string;

    heading: string;

    /**
     * Start time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    startTime: string;
  };

  public shadowRoot!: ShadowRoot;
  private endTime?: number;
  private startTime?: number;

  private onTimerTickBound = this.onTimerTick.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    addEventListener('timer-tick', this.onTimerTickBound);
    startTimer();
    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('timer-tick', this.onTimerTickBound);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    switch (name) {
      case 'data-start-time':
        this.startTime = newValue ? parseInt(newValue, 10) * 1000 : undefined;
        break;
      case 'data-end-time':
        this.endTime = newValue ? parseInt(newValue, 10) * 1000 : undefined;
        break;
    }

    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private onTimerTick(event: WindowEventMap['timer-tick']) {
    const remaining = timeLeft(event.detail.timestamp, this.endTime);

    const wrappingElement = this.shadowRoot.querySelector('p');
    if (!remaining || this.startTime > event.detail.timestamp) {
      wrappingElement.classList.remove('active');
      return;
    }

    if (!wrappingElement.classList.contains('active')) {
      wrappingElement.classList.add('active');
    }

    const daysRemainingElement = this.shadowRoot.querySelector<HTMLElement>('.days-remaining');
    if (remaining.days === 0) {
      daysRemainingElement.textContent = '';
    } else if (remaining.days === 1) {
      daysRemainingElement.textContent = '1 day';
    } else {
      daysRemainingElement.textContent = [remaining.days.toString(), 'days'].join(' ');
    }

    const hours = remaining.hours.toString().padStart(2, '0');
    const minutes = remaining.minutes.toString().padStart(2, '0');
    const seconds = remaining.seconds.toString().padStart(2, '0');
    const formatted = [hours, ':', minutes, ':', seconds].join('');

    const timeRemainingElement = this.shadowRoot.querySelector<HTMLElement>('.time-remaining');
    timeRemainingElement.textContent = formatted;
  }

  private render() {
    const heading = this.shadowRoot.querySelector<HTMLElement>('span.timer-heading');
    heading.textContent = this.dataset.heading;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'product-detail-countdown-timer': ProductDetailCountdownTimer;
  }
}

if (!customElements.get('product-detail-countdown-timer')) {
  customElements.define('product-detail-countdown-timer', ProductDetailCountdownTimer);
}
